<template>
   <div class="col-12 p-0">
      <div class="card mb-1" :class="index % 2 == 0 ? '' : 'invert'">
         <div class="card-body p-3">
            <div class="row m-0">
               <div class="col-md-10 p-0">
                  <div class="row m-0 font-13">
                     <div class="col-12 p-0 text-center text-md-start mb-2">
                        <strong class="limitador font-16">{{ jogador.nome != null ? jogador.nome : 'Desconhecido' }}</strong>
                     </div>
                     <div class="col-6 col-md-3 p-0 text-center text-md-start mb-1 mb-md-0">
                        <div class="limitador">
                           <i class="far fa-hashtag color-theme font-11 me-1"></i>
                           <small class="d-none d-sm-inline text-secondary">Identidade:</small> {{ jogador.id != null ? jogador.id : '-' }} 
                        </div>
                     </div>
                     <div class="col-6 col-md-3 p-0 text-center text-md-start mb-1 mb-md-0">
                        <div class="limitador">
                           <i class="fab fa-discord color-theme font-10 me-1"></i>
                           <small class="d-none d-sm-inline text-secondary">Discord:</small> {{ jogador.discord != null ? String(jogador.discord).replace('discord:', '') : '-' }}
                        </div>
                     </div>
                     <div class="col-6 col-md-3 p-0 text-center text-md-start mb-1 mb-md-0">
                        <div class="limitador cursor-pointer" @click="changeMoney">
                           <i class="far fa-money-bill-alt color-theme font-10 me-1"></i>
                           <small class="d-none d-sm-inline text-secondary">Banco:</small> {{ jogador.bank != null ? jogador.bank : '-' }}
                        </div>
                     </div>
                     <div class="col-6 col-md-3 p-0 text-center text-md-start mb-1 mb-md-0">
                        <div class="limitador cursor-pointer" @click="changeCoins">
                           <i class="far fa-coins color-theme font-10 me-1"></i>
                           <small class="d-none d-sm-inline text-secondary">Coins:</small> {{ jogador.coins != null ? jogador.coins : '-' }}
                        </div>
                     </div>
                     <div class="col-6 col-md-3 p-0 text-center text-md-start mb-0">
                        <div class="limitador cursor-pointer" @click="changeCharacters">
                           <i class="far fa-users color-theme font-10 me-1"></i>
                           <small class="d-none d-sm-inline text-secondary">Personagens:</small> {{ jogador.chars != null ? jogador.chars : '-'  }}
                        </div>
                     </div>
                     <div class="col-6 col-md-3 p-0 text-center text-md-start mb-0">
                        <div class="limitador cursor-pointer" @click="changeParkingSpaces">
                           <i class="far fa-warehouse color-theme font-10 me-1"></i>
                           <small class="d-none d-sm-inline text-secondary">Garagem:</small> {{ jogador.garage != null ? jogador.garage : '-' }}
                        </div>
                     </div>
                     <div class="col-6 col-md-3 p-0 text-center text-md-start mb-0">
                        <div class="limitador cursor-pointer text-success" @click="toggleWhitelist" v-if="jogador.whitelist == true">
                           <i class="far fa-scroll font-10 me-1"></i>
                           <small class="d-none d-sm-inline">Whitelist:</small> Sim
                        </div>
                        <div class="limitador cursor-pointer text-danger" @click="toggleWhitelist" v-else>
                           <i class="far fa-scroll font-10 me-1"></i>
                           <small class="d-none d-sm-inline">Whitelist:</small> {{ jogador.whitelist != null ? 'Não' : '-' }}
                        </div>
                     </div>
                     <div class="col-6 col-md-3 p-0 text-center text-md-start mb-0">
                        <div class="limitador cursor-pointer text-success" @click="toggleBan" v-if="jogador.banned == true">
                           <i class="far fa-ban font-10 me-1"></i>
                           <small class="d-none d-sm-inline">Banido:</small> Sim
                        </div>
                        <div class="limitador cursor-pointer text-danger" @click="toggleBan" v-else>
                           <i class="far fa-ban font-10 me-1"></i>
                           <small class="d-none d-sm-inline">Banido:</small> {{ jogador.banned != null ? 'Não' : '-' }}
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-2 p-0 d-inline-flex align-items-center justify-content-center mt-md-0 mt-3">
                  <a class="col btn-icone color-theme" @click="getInfoUser" href="javascript:;">
                     <i class="far fa-info-circle font-17"></i><small>Detalhes</small>
                  </a>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
	name: 'Jogador',
   props: ['jogador', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      changeMoney : function () {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Alterar banco',
            text: 'Informe a quantidade',
            input: 'text',
            inputPlaceholder: 'Quantidade',
            customClass: {
               input: 'shadow-none'
            },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            inputValidator: (value) => {
               if (!$.isNumeric(value)) {
                  return 'Valor inválido!'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'game/changeMoney',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     id: ref.jogador.id,
                     amount: parseInt(result.value)
                  }
               }).then(function () {
                  ref.jogador.bank = parseInt(result.value)

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Alteração salva!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         })
      },
      changeCoins : function () {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Alterar coins',
            text: 'Informe a quantidade',
            input: 'text',
            inputPlaceholder: 'Quantidade',
            customClass: {
               input: 'shadow-none'
            },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            inputValidator: (value) => {
               if (!$.isNumeric(value)) {
                  return 'Valor inválido!'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'game/changeCoins',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     id: ref.jogador.id,
                     amount: parseInt(result.value)
                  }
               }).then(function () {
                  ref.jogador.coins = parseInt(result.value)

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Alteração salva!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         })
      },
      changeCharacters : function () {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Alterar número de personagens',
            text: 'Informe a quantidade',
            input: 'text',
            inputPlaceholder: 'Quantidade',
            customClass: {
               input: 'shadow-none'
            },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            inputValidator: (value) => {
               if (!$.isNumeric(value)) {
                  return 'Valor inválido!'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'game/changeCharacters',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     id: ref.jogador.id,
                     amount: parseInt(result.value)
                  }
               }).then(function () {
                  ref.jogador.chars = parseInt(result.value)

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Alteração salva!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         })
      },
      changeParkingSpaces : function () {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Alterar vagas de garagem',
            text: 'Informe a quantidade',
            input: 'text',
            inputPlaceholder: 'Quantidade',
            customClass: {
               input: 'shadow-none'
            },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            inputValidator: (value) => {
               if (!$.isNumeric(value)) {
                  return 'Valor inválido!'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'game/changeParkingSpaces',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     id: ref.jogador.id,
                     amount: parseInt(result.value)
                  }
               }).then(function () {
                  ref.jogador.garage = parseInt(result.value)

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Alteração salva!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         })
      },
      toggleWhitelist : function () {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: (this.jogador.whitelist == true ? 'Retirar' : 'Adicionar') +' whitelist',
            text: 'Tem certeza?',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'game/toggleWhitelist',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     discord: String(ref.jogador.discord).replace('discord:', '')
                  }
               }).then(function () {
                  ref.jogador.whitelist = !ref.jogador.whitelist

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Alteração salva!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         })
      },
      toggleBan : function () {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: (this.jogador.banned == true ? 'Retirar' : 'Adicionar') +' banimento',
            text: 'Tem certeza?',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'game/toggleBan',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     discord: String(ref.jogador.discord).replace('discord:', '')
                  }
               }).then(function () {
                  ref.jogador.banned = !ref.jogador.banned

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Alteração salva!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         })
      },
      getInfoUser : function () {
         this.$emit('getInfoUser', this.jogador.id)
      }
   }
}

</script>

<style scoped>

div.cursor-pointer:hover {
   text-decoration: underline;
}

</style>